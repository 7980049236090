.App {
  text-align: center;
}

/* Leaflet container must have defined size in order to render map correctly. */
.leaflet-container {
  width: 100vw;
  height: 90vh;
  position:absolute;
  bottom: 0;
}

.leaflet-heatmap-layer {
  opacity: .65;
}